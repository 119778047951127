export const RESET_AUTHENTICATION_STATE = 'auth/RESET_AUTHENTICATION_STATE';
export const SET_AUTH_CODE_TOKEN = 'auth/SET_AUTH_CODE_TOKEN';
export const SET_DEVICE_DETAILS = 'auth/SET_DEVICE_DETAILS';
export const SET_FINGERPRINT_DETAILS = 'auth/SET_FINGERPRINT_DETAILS';
export const SET_REDIRECT_FROM = 'auth/SET_REDIRECT_FROM';
export const SET_IS_IFRAME = 'auth/SET_IS_IFRAME';
export const SET_REDIRECT_TO = 'auth/SET_REDIRECT_TO';
export const SET_IS_LOADING = 'auth/SET_IS_LOADING';
export const SET_VERIFY_CHANNEL = 'auth/SET_VERIFY_CHANNEL';
export const SET_AUTH_STATE = 'auth/SET_AUTH_STATE';
export const SET_REGISTRATION_TOKEN = 'auth/SET_REGISTRATION_TOKEN';
export const SET_FROM_2FA_REDIRECT = 'auth/SET_FROM_2FA_REDIRECT';
