import { ActionType } from 'typesafe-actions';

import * as auth from './actions';
import {
  RESET_AUTHENTICATION_STATE,
  SET_AUTH_CODE_TOKEN,
  SET_DEVICE_DETAILS,
  SET_FINGERPRINT_DETAILS,
  SET_REDIRECT_FROM,
  SET_REDIRECT_TO,
  SET_IS_LOADING,
  SET_VERIFY_CHANNEL,
  SET_AUTH_STATE,
  SET_REGISTRATION_TOKEN,
  SET_FROM_2FA_REDIRECT,
  SET_IS_IFRAME,
} from './constants';

export type AuthAction = ActionType<typeof auth>;

export type AuthState = {
  authCodeToken: string | undefined; // Token sent along with auth code
  registerToken: string | undefined; // Token sent along with registration request
  deviceDetails: { [key: string]: any };
  fingerPrintDetails: { [key: string]: any };
  redirectFrom: 'POWER' | 'MOBILE' | 'UNIFIED' | undefined;
  isIframe: boolean;
  redirectTo: string | undefined;
  isLoading: boolean;
  verifyChannel: string | undefined;
  isRedirectedFrom2FA: boolean;
};

const defaultState: AuthState = {
  authCodeToken: undefined,
  registerToken: undefined,
  deviceDetails: {},
  fingerPrintDetails: {},
  redirectFrom: undefined,
  isIframe: false,
  redirectTo: undefined,
  isLoading: false,
  verifyChannel: undefined,
  isRedirectedFrom2FA: false,
};

export default (state = defaultState, action: AuthAction): AuthState => {
  switch (action.type) {
    case SET_AUTH_CODE_TOKEN:
      return {
        ...state,
        authCodeToken: action.payload,
      };
    case SET_REGISTRATION_TOKEN:
      return {
        ...state,
        registerToken: action.payload,
      };
    case RESET_AUTHENTICATION_STATE:
      return {
        ...defaultState,
        deviceDetails: { ...state.deviceDetails },
        fingerPrintDetails: { ...state.fingerPrintDetails },
        redirectFrom: state.redirectFrom,
        redirectTo: state.redirectTo,
        isIframe: state.isIframe,
      };
    case SET_DEVICE_DETAILS: {
      return {
        ...state,
        deviceDetails: action.payload,
      };
    }
    case SET_FINGERPRINT_DETAILS: {
      return {
        ...state,
        fingerPrintDetails: action.payload,
      };
    }
    case SET_REDIRECT_FROM: {
      return {
        ...state,
        redirectFrom: action.payload,
      };
    }
    case SET_REDIRECT_TO: {
      return {
        ...state,
        redirectTo: action.payload,
      };
    }
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case SET_VERIFY_CHANNEL: {
      return {
        ...state,
        verifyChannel: action.payload,
      };
    }
    case SET_AUTH_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_IS_IFRAME: {
      return {
        ...state,
        isIframe: action.payload,
      };
    }
    case SET_FROM_2FA_REDIRECT: {
      return {
        ...state,
        isRedirectedFrom2FA: action.payload,
      };
    }
    default:
      return state;
  }
};
